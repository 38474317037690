import React from "react";
import { Container, Row, Col } from "reactstrap";

import Highlight from "../components/Highlight";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

export const MobileSsoComponent = () => {
  const { loginWithRedirect } = useAuth0();
  const ssoConnection = "m2wv-mobilesso-db"

  loginWithRedirect({
    authorizationParams: {
      connection: ssoConnection,
      redirect_uri: `https://${window.location.host}/profile`
    }
  })
  
  return (
    <Container className="mb-5">
     <h1>Performing SSO from mobile app</h1>
    </Container>
  );
};

export default MobileSsoComponent;
